<template>
  <div>
    <invoice />
  </div>
</template>

<script>
import Invoice from '@/@core/components/Invoice.vue'

export default {
  components: {
    Invoice,
  },
}
</script>

<style lang="scss" scoped>

</style>
