var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-preview-wrapper"},[(_vm.order)?_c('b-row',{staticClass:"invoice-preview"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('div',{staticClass:"\n              d-flex\n              justify-content-between\n              flex-md-row flex-column\n              invoice-spacing\n              mt-0\n            "},[_c('div',[_c('div',{staticClass:"logo-wrapper"},[_c('b-img',{attrs:{"id":"logo","src":_vm.appLogoImage,"height":"50","alt":"logo"}})],1),_c('p',{staticClass:"card-text mb-25"},[_vm._v(" "+_vm._s(_vm.order.store.establishment_attributes.name)+" ")]),_c('p',{staticClass:"card-text mb-25"},[_vm._v(" "+_vm._s(_vm.order.store.name)+" ")])]),_c('div',{staticClass:"mt-md-0 mt-2"},[_c('h4',{staticClass:"invoice-title"},[_vm._v(" Ticket "),_c('span',{staticClass:"invoice-number"},[_vm._v(" #"+_vm._s(_vm.order.auto_inc_folio)+" ")])]),_c('div',{staticClass:"invoice-date-wrapper"},[_c('p',{staticClass:"invoice-date-title"},[_vm._v("Fecha:")]),_c('p',{staticClass:"invoice-date"},[_vm._v(" "+_vm._s(_vm._f("fullDate")(_vm.order.created_at))+" ")])]),_c('div',{staticClass:"invoice-date-wrapper"},[_c('p',{staticClass:"invoice-date-title"},[_vm._v("Hora:")]),_c('p',{staticClass:"invoice-date"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.order.created_at))+" ")])])])])]),(
            _vm.order.is_refund === true &&
            _vm.order.delivery_status === 'cancelled_by_customer'
          )?_c('div',[_c('b-card',{staticClass:"text-center",staticStyle:{"color":"red"},attrs:{"no-body":""}},[_c('div',{staticClass:"bg-danger text-light"},[_vm._v(" Esta compra ha sido cancelada por el cliente ")])])],1):_vm._e(),(
            _vm.order.is_refund === true &&
            _vm.order.delivery_status === 'cancelled_by_establishment'
          )?_c('div',[_c('b-card',{staticClass:"text-center",staticStyle:{"color":"red"},attrs:{"no-body":""}},[_c('div',{staticClass:"bg-danger text-light"},[_vm._v(" Esta compra ha sido cancelada por administrador del negocio ")])])],1):_vm._e(),(
            _vm.order.delivery_status === 'rejected_by_dispatcher' &&
            _vm.userData.role_name === 'dispatcher'
          )?_c('div',[_c('b-card',{staticClass:"text-center",staticStyle:{"color":"red"},attrs:{"no-body":""}},[_c('div',{staticClass:"bg-danger text-light"},[_vm._v("Rechazaste esta entrega")])])],1):_vm._e(),(
            _vm.order.delivery_status === 'delivered' &&
            _vm.order.order_mode === 'online'
          )?_c('div',[_c('b-card',{staticClass:"text-center",staticStyle:{"color":"red"},attrs:{"no-body":""}},[_c('div',{staticClass:"bg-success text-light"},[_vm._v("Compra entregada")])])],1):_vm._e(),_c('hr',{staticClass:"invoice-spacing"}),_c('b-card-body',{staticClass:"invoice-padding pt-0"},[_c('b-row',{staticClass:"invoice-spacing"},[_c('b-col',{staticClass:"p-0",attrs:{"xl":"6","cols":"12"}},[_c('div',[_c('h4',{staticClass:"mb-0.4"},[_vm._v("Detalles:")]),_c('table',[_c('tbody',[(_vm.userData.role_name === 'customer')?_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Cliente:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.userData.name)+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Tipo:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("orderType")(_vm.order.order_type))+" "+_vm._s(_vm._f("orderMode")(_vm.order.order_mode))+" ")])]),_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Tipo de pago:")]),_c('td',{staticClass:"font-weight-bolder"},[(_vm.order.order_payments_attributes[0])?_c('span',[_vm._v(" "+_vm._s(_vm._f("paymentType")(_vm.order.order_payments_attributes[0].payment_type))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("paymentTypeNum")(_vm.order.payment_type))+" ")])])]),_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Estado de pago:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("paymentStatus")(_vm.order.payment_status))+" ")])]),_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("¿Es fiado?:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.order.is_loan === true ? "Si" : "No")+" ")])]),_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("¿Es devolución?:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.order.is_refund === true ? "Si" : "No")+" ")])]),(
                        _vm.order.order_mode !== 'onsite' &&
                        (_vm.userData.role_name === 'customer' ||
                          _vm.userData.role_name === 'establishment_admin')
                      )?_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Entregar en:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.order.delivery_location.street)+" "+_vm._s(_vm.order.delivery_location.ext_number)+" (num. int "+_vm._s(_vm.order.delivery_location.int_number)+" ), CP: "+_vm._s(_vm.order.delivery_location.postal_code)+", "+_vm._s(_vm.order.delivery_location.city)+", "+_vm._s(_vm.order.delivery_location.state)+", "+_vm._s(_vm.order.delivery_location.country)+" ")])]):_vm._e(),(
                        _vm.order.order_mode !== 'onsite' &&
                        _vm.userData.role_name === 'dispatcher' &&
                        (_vm.order.delivery_status === 'on_the_way' ||
                          _vm.order.delivery_status === 'delivered')
                      )?_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Entregar en:")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.order.delivery_location.street)+" "+_vm._s(_vm.order.delivery_location.ext_number)+" (num. int "+_vm._s(_vm.order.delivery_location.int_number)+" ), CP: "+_vm._s(_vm.order.delivery_location.postal_code)+", "+_vm._s(_vm.order.delivery_location.city)+", "+_vm._s(_vm.order.delivery_location.state)+", "+_vm._s(_vm.order.delivery_location.country)+" ")])]):_vm._e(),(
                        _vm.order.order_mode !== 'onsite' &&
                        _vm.userData.role_name === 'dispatcher' &&
                        (_vm.order.delivery_status === 'assigned' ||
                          _vm.order.delivery_status === 'assigning')
                      )?_c('tr',[_c('td',{staticClass:"pr-1"},[_vm._v("Entregar en (aprox):")]),_c('td',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.order.delivery_location.street)+", CP: "+_vm._s(_vm.order.delivery_location.postal_code)+", "+_vm._s(_vm.order.delivery_location.city)+", "+_vm._s(_vm.order.delivery_location.state)+", "+_vm._s(_vm.order.delivery_location.country)+" ")])]):_vm._e()])]),_c('b-card',[(
                      _vm.order.order_mode !== 'onsite' &&
                      (_vm.order.delivery_status === 'assigning' ||
                        _vm.order.delivery_status === 'assigned') &&
                      _vm.order.is_refund === false &&
                      _vm.userData.role_name === 'dispatcher'
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.dispatcherConfirms()}}},[_vm._v(" Aceptar entrega ")]):_vm._e(),(
                      _vm.order.order_mode !== 'onsite' &&
                      (_vm.order.delivery_status === 'assigning' ||
                        _vm.order.delivery_status === 'assigned') &&
                      _vm.order.is_refund === false &&
                      _vm.userData.role_name === 'dispatcher'
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"danger","block":""},on:{"click":function($event){return _vm.dispatcherRejects()}}},[_vm._v(" Rechazar entrega ")]):_vm._e(),(
                      (_vm.order.order_mode !== 'onsite' &&
                        _vm.order.delivery_status === 'on_the_way' &&
                        _vm.order.is_refund === false &&
                        _vm.userData.role_name === 'dispatcher') ||
                      (_vm.order.order_mode !== 'onsite' &&
                        _vm.order.delivery_status !== 'delivered' &&
                        _vm.order.is_refund === false &&
                        _vm.userData.role_name === 'establishment_admin')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"warning","block":"","href":'https://www.google.com/maps/search/?api=1&query=' +
                      _vm.order.delivery_location.lat +
                      '%2C' +
                      _vm.order.delivery_location.lng}},[_c('feather-icon',{attrs:{"icon":"MapIcon","size":"18"}}),_vm._v(" Ver mapa ")],1):_vm._e(),(
                      (_vm.order.order_mode !== 'onsite' &&
                        _vm.order.delivery_status === 'on_the_way' &&
                        _vm.order.is_refund === false &&
                        _vm.userData.role_name === 'dispatcher') ||
                      (_vm.order.order_mode !== 'onsite' &&
                        _vm.order.delivery_status !== 'delivered' &&
                        _vm.order.is_refund === false &&
                        _vm.userData.role_name === 'establishment_admin')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.$bvModal.show('mark-as-delivered')}}},[_vm._v(" Finalizar entrega ")]):_vm._e()],1)],1)])],1)],1),_c('b-modal',{attrs:{"id":"mark-as-delivered","title":"Confirmar entrega a cliente","no-stacking":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","ok-title":"Finalizar","ok-variant":"success","cancel-title":"Cancelar","cancel-variant":"outline-danger"},on:{"ok":function($event){return _vm.dispatcherConfirmsDelivery()}}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",staticStyle:{"color":"orange"},attrs:{"size":"50","icon":"AlertTriangleIcon"}}),(_vm.order.payment_status === 'pending')?_c('b-card-title',{staticClass:"ml-25",staticStyle:{"color":"orange"}},[_vm._v(" Antes de entregar cobra al cliente $"+_vm._s(_vm._f("money")(_vm.order.total))+" ")]):_vm._e(),(_vm.order.payment_status === 'payed')?_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" ¿Estás seguro que quieres marcar como entregado? ")]):_vm._e()],1)])],1),(_vm.order.order_store_products_attributes.length > 0)?_c('b-table-lite',{attrs:{"responsive":"","items":_vm.order.order_store_products_attributes,"fields":[
            { key: 'name', label: 'producto' },
            { key: 'unit_price', label: 'precio' },
            { key: 'units', label: 'unidades' },
            'total' ]},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-card-text',{staticClass:"font-weight-bold mb-25 d-flex"},[_c('b-img',{staticClass:"mr-1",attrs:{"width":"40px","height":"40px","src":("" + (data.item.store_product_attributes.product_attributes.logo)),"rounded":""}}),_c('div',[_vm._v(" "+_vm._s(data.item.store_product_attributes.product_attributes.name)+" "),_c('div',_vm._l((data.item.store_product_attributes
                      .store_product_variant_attributes),function(variant,n){return _c('b-badge',{key:("variant-" + n),staticClass:"mr-1 mt-1",attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(variant.variant_option_attributes.option_name)+" ")])}),1),_c('div',_vm._l((data.item.store_product_attributes
                      .product_attributes.categories_names),function(category){return _c('b-badge',{key:("category-" + category),staticClass:"mr-1 mt-1",attrs:{"variant":"light-info"}},[_vm._v(" "+_vm._s(category)+" ")])}),1)])],1)]}}],null,false,3625339654)}):_vm._e(),(_vm.order.emida_response)?_c('table',{staticClass:"table-responsive table b-table"},[_c('thead',{staticStyle:{"background-color":"#343d55"}},[_c('th',[_vm._v("PRODUCTO")]),_c('th',[_vm._v("REFERENCIA")]),_c('th',[_vm._v("CÓDIGO DE AUTORIZACIÓN")])]),_c('tr',[_c('td',[_c('div',[_vm._v(" "+_vm._s(JSON.parse(_vm.order.emida_response.request).productName)+" "),_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Nota: "),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.order.emida_response.response_message)+" ")])])])])]),_c('td',[_vm._v(_vm._s(_vm.order.emida_response.pin))]),_c('td',[_vm._v(_vm._s(_vm.order.emida_response.carrier_control_no))])])]):_vm._e(),_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('b-row',[_c('b-col',{staticClass:"mt-md-0 mt-3",attrs:{"cols":"12","md":"6","order":"2","order-md":"1"}},[_c('b-card-text',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Te atendió:")]),_c('span',{staticClass:"ml-75"},[_vm._v(_vm._s(_vm.order.store_clerk.name))])])],1),_c('b-col',{staticClass:"mt-md-6 d-flex justify-content-end",attrs:{"cols":"12","md":"6","order":"1","order-md":"2"}},[(
                  _vm.order.emida_response &&
                  _vm.order.emida_response.flow_type === 'b'
                )?_c('div',{staticClass:"invoice-total-wrapper"},[_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("Subtotal:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $"+_vm._s(_vm._f("money")((Number(_vm.order.total) - Number(JSON.parse(_vm.order.emida_response.request).fee))))+" ")])]),_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("Comisión:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $"+_vm._s(_vm._f("money")(JSON.parse(_vm.order.emida_response.request).fee))+" ")])]),_c('hr',{staticClass:"my-50"}),_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("Total:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $"+_vm._s(_vm._f("money")(_vm.order.total))+" ")])])]):_c('div',{staticClass:"invoice-total-wrapper"},[_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("Subtotal:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $"+_vm._s(_vm._f("money")(_vm.order.subtotal))+" ")])]),_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("IVA:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $ "+_vm._s(_vm._f("money")(_vm.order.iva))+" ")])]),_c('hr',{staticClass:"my-50"}),_c('div',{staticClass:"invoice-total-item"},[_c('p',{staticClass:"invoice-total-title"},[_vm._v("Total:")]),_c('p',{staticClass:"invoice-total-amount"},[_vm._v(" $"+_vm._s(_vm._f("money")(_vm.order.total))+" ")])])])])],1)],1),_c('b-card-body',{staticClass:"invoice-padding pt-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nota: ")]),_c('span',[_vm._v("Gracias por tu preferencia! ✌")])])],1)],1),_c('b-col',{staticClass:"invoice-actions",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[(
            (_vm.order.order_mode === 'onsite' ||
              _vm.order.delivery_status !== 'delivered') &&
            _vm.order.is_refund === false &&
            (_vm.userData.role_name !== 'dispatcher' ||
              _vm.userData.role_name !== 'store_clerk')
          )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"danger","block":""},on:{"click":_vm.confirmCancelation}},[_vm._v(" Cancelar orden ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":_vm.printInvoice}},[_vm._v(" Imprimir ")]),(_vm.orderId)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.emptyCart()}}},[_vm._v(" Cerrar ticket ")]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }