<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="order" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img id="logo" :src="appLogoImage" height="50" alt="logo" />
                </div>
                <p class="card-text mb-25">
                  {{ order.store.establishment_attributes.name }}
                </p>
                <p class="card-text mb-25">
                  {{ order.store.name }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Ticket
                  <span class="invoice-number">
                    #{{ order.auto_inc_folio }}
                  </span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Fecha:</p>
                  <p class="invoice-date">
                    {{ order.created_at | fullDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Hora:</p>
                  <p class="invoice-date">
                    {{ order.created_at | time }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <div
            v-if="
              order.is_refund === true &&
              order.delivery_status === 'cancelled_by_customer'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por el cliente
              </div>
            </b-card>
          </div>

          <div
            v-if="
              order.is_refund === true &&
              order.delivery_status === 'cancelled_by_establishment'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por administrador del negocio
              </div>
            </b-card>
          </div>

          <div
            v-if="
              order.delivery_status === 'rejected_by_dispatcher' &&
              userData.role_name === 'dispatcher'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-danger text-light">Rechazaste esta entrega</div>
            </b-card>
          </div>

          <div
            v-if="
              order.delivery_status === 'delivered' &&
              order.order_mode === 'online'
            "
          >
            <b-card style="color: red" no-body class="text-center">
              <div class="bg-success text-light">Compra entregada</div>
            </b-card>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Order & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Orer Details -->
              <b-col xl="6" cols="12" class="p-0">
                <div>
                  <h4 class="mb-0.4">Detalles:</h4>
                  <table>
                    <tbody>
                      <tr v-if="userData.role_name === 'customer'">
                        <td class="pr-1">Cliente:</td>
                        <td class="font-weight-bolder">
                          {{ userData.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Tipo:</td>
                        <td class="font-weight-bolder">
                          {{ order.order_type | orderType }}
                          {{ order.order_mode | orderMode }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Tipo de pago:</td>
                        <td class="font-weight-bolder">
                          <span v-if="order.order_payments_attributes[0]">
                            {{
                              order.order_payments_attributes[0].payment_type
                                | paymentType
                            }}
                          </span>
                          <span v-else>
                            {{ order.payment_type | paymentTypeNum }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Estado de pago:</td>
                        <td class="font-weight-bolder">
                          {{ order.payment_status | paymentStatus }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">¿Es fiado?:</td>
                        <td class="font-weight-bolder">
                          {{ order.is_loan === true ? "Si" : "No" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">¿Es devolución?:</td>
                        <td class="font-weight-bolder">
                          {{ order.is_refund === true ? "Si" : "No" }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          order.order_mode !== 'onsite' &&
                          (userData.role_name === 'customer' ||
                            userData.role_name === 'establishment_admin')
                        "
                      >
                        <td class="pr-1">Entregar en:</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}
                          {{ order.delivery_location.ext_number }} (num. int
                          {{ order.delivery_location.int_number }} ), CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          order.order_mode !== 'onsite' &&
                          userData.role_name === 'dispatcher' &&
                          (order.delivery_status === 'on_the_way' ||
                            order.delivery_status === 'delivered')
                        "
                      >
                        <td class="pr-1">Entregar en:</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}
                          {{ order.delivery_location.ext_number }} (num. int
                          {{ order.delivery_location.int_number }} ), CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          order.order_mode !== 'onsite' &&
                          userData.role_name === 'dispatcher' &&
                          (order.delivery_status === 'assigned' ||
                            order.delivery_status === 'assigning')
                        "
                      >
                        <td class="pr-1">Entregar en (aprox):</td>
                        <td class="font-weight-bolder">
                          {{ order.delivery_location.street }}, CP:
                          {{ order.delivery_location.postal_code }},
                          {{ order.delivery_location.city }},
                          {{ order.delivery_location.state }},
                          {{ order.delivery_location.country }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-card>
                    <b-button
                      v-if="
                        order.order_mode !== 'onsite' &&
                        (order.delivery_status === 'assigning' ||
                          order.delivery_status === 'assigned') &&
                        order.is_refund === false &&
                        userData.role_name === 'dispatcher'
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      class="mb-75"
                      block
                      @click="dispatcherConfirms()"
                    >
                      Aceptar entrega
                    </b-button>
                    <b-button
                      v-if="
                        order.order_mode !== 'onsite' &&
                        (order.delivery_status === 'assigning' ||
                          order.delivery_status === 'assigned') &&
                        order.is_refund === false &&
                        userData.role_name === 'dispatcher'
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="danger"
                      class="mb-75"
                      block
                      @click="dispatcherRejects()"
                    >
                      Rechazar entrega
                    </b-button>
                    <b-button
                      v-if="
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status === 'on_the_way' &&
                          order.is_refund === false &&
                          userData.role_name === 'dispatcher') ||
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status !== 'delivered' &&
                          order.is_refund === false &&
                          userData.role_name === 'establishment_admin')
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="warning"
                      class="mb-75"
                      block
                      :href="
                        'https://www.google.com/maps/search/?api=1&query=' +
                        order.delivery_location.lat +
                        '%2C' +
                        order.delivery_location.lng
                      "
                    >
                      <feather-icon icon="MapIcon" size="18" />
                      Ver mapa
                    </b-button>
                    <b-button
                      v-if="
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status === 'on_the_way' &&
                          order.is_refund === false &&
                          userData.role_name === 'dispatcher') ||
                        (order.order_mode !== 'onsite' &&
                          order.delivery_status !== 'delivered' &&
                          order.is_refund === false &&
                          userData.role_name === 'establishment_admin')
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="success"
                      class="mb-75"
                      block
                      @click="$bvModal.show('mark-as-delivered')"
                    >
                      Finalizar entrega
                    </b-button>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Invoice Description: Table for store_products -->
          <b-modal
            id="mark-as-delivered"
            title="Confirmar entrega a cliente"
            no-stacking
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            ok-title="Finalizar"
            ok-variant="success"
            cancel-title="Cancelar"
            cancel-variant="outline-danger"
            @ok="dispatcherConfirmsDelivery()"
          >
            <b-card>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="50"
                  class="mr-1"
                  icon="AlertTriangleIcon"
                  style="color:orange;'"
                />
                <b-card-title
                  v-if="order.payment_status === 'pending'"
                  class="ml-25"
                  style="color:orange;'"
                >
                  Antes de entregar cobra al cliente ${{ order.total | money }}
                </b-card-title>
                <b-card-title
                  v-if="order.payment_status === 'payed'"
                  class="ml-25"
                >
                  ¿Estás seguro que quieres marcar como entregado?
                </b-card-title>
              </div>
            </b-card>
          </b-modal>
          <b-table-lite
            v-if="order.order_store_products_attributes.length > 0"
            responsive
            :items="order.order_store_products_attributes"
            :fields="[
              { key: 'name', label: 'producto' },
              { key: 'unit_price', label: 'precio' },
              { key: 'units', label: 'unidades' },
              'total',
            ]"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25 d-flex">
                <b-img
                  width="40px"
                  height="40px"
                  :src="`${data.item.store_product_attributes.product_attributes.logo}`"
                  rounded
                  class="mr-1"
                />
                <div>
                  {{
                    data.item.store_product_attributes.product_attributes.name
                  }}
                  <div>
                    <b-badge
                      v-for="(variant, n) in data.item.store_product_attributes
                        .store_product_variant_attributes"
                      :key="`variant-${n}`"
                      class="mr-1 mt-1"
                      pill
                      variant="primary"
                    >
                      {{ variant.variant_option_attributes.option_name }}
                    </b-badge>
                  </div>
                  <div>
                    <b-badge
                      v-for="category in data.item.store_product_attributes
                        .product_attributes.categories_names"
                      :key="`category-${category}`"
                      variant="light-info"
                      class="mr-1 mt-1"
                    >
                      {{ category }}
                    </b-badge>
                  </div>
                </div>
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Emida products table -->
          <table
            v-if="order.emida_response"
            class="table-responsive table b-table"
          >
            <thead style="background-color: #343d55">
              <th>PRODUCTO</th>
              <th>REFERENCIA</th>
              <th>CÓDIGO DE AUTORIZACIÓN</th>
            </thead>
            <tr>
              <td>
                <div>
                  {{ JSON.parse(order.emida_response.request).productName }}
                  <div>
                    <p class="font-weight-bold">
                      Nota:
                      <span class="text-muted">
                        {{ order.emida_response.response_message }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td>{{ order.emida_response.pin }}</td>
              <td>{{ order.emida_response.carrier_control_no }}</td>
            </tr>
          </table>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Te atendió:</span>
                  <span class="ml-75">{{ order.store_clerk.name }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <!-- Total div for service payment -->
                <div
                  v-if="
                    order.emida_response &&
                    order.emida_response.flow_type === 'b'
                  "
                  class="invoice-total-wrapper"
                >
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      ${{
                        (Number(order.total) -
                          Number(JSON.parse(order.emida_response.request).fee))
                          | money
                      }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Comisión:</p>
                    <p class="invoice-total-amount">
                      ${{
                        JSON.parse(order.emida_response.request).fee | money
                      }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">
                      ${{ order.total | money }}
                    </p>
                  </div>
                </div>
                <!-- Total div for the other type of orders -->
                <div v-else class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      ${{ order.subtotal | money }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">IVA:</p>
                    <p class="invoice-total-amount">
                      $ {{ order.iva | money }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">
                      ${{ order.total | money }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Note -->
          <b-card-body class="invoice-padding pt-3">
            <span class="font-weight-bold">Nota: </span>
            <span>Gracias por tu preferencia! ✌</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle.sidebar-send-invoice
          variant="primary"
          class="mb-75"
          block
        >
          Enviar por correo
        </b-button> -->

          <!-- Button: DOwnload -->
          <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mb-75"
          block
        >
          Descargar
        </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-if="
              (order.order_mode === 'onsite' ||
                order.delivery_status !== 'delivered') &&
              order.is_refund === false &&
              (userData.role_name !== 'dispatcher' ||
                userData.role_name !== 'store_clerk')
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="confirmCancelation"
          >
            Cancelar orden
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir
          </b-button>
          <b-button
            v-if="orderId"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="emptyCart()"
          >
            Cerrar ticket
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable */
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BBadge,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import { $themeConfig } from "@themeConfig";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2"; // Import Swal2 library

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    Logo,
    BBadge,
    Swal,
  },
  props: {
    orderId: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup() {
    const invoiceData = ref(null);
    const paymentDetails = ref({});

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: "Native App Development",
        taskDescription:
          "Developed a full stack native app using React Native, Bootstrap & Python",
        rate: "$60.00",
        hours: "30",
        total: "$1,800.00",
      },
      {
        taskTitle: "UI Kit Design",
        taskDescription:
          "Designed a UI kit for native app using Sketch, Figma & Adobe XD",
        rate: "$60.00",
        hours: "20",
        total: "$1200.00",
      },
    ];

    const { appLogoImage } = $themeConfig.app;

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      appLogoImage,
    };
  },
  props: {
    orderId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      order: undefined,
      locationLink: null,
    };
  },
  beforeMount() {
    this.fetchOrder(this.orderId ? this.orderId : this.$route.params.id).then(
      (response) => {
        this.order = response;
      }
    );
  },
  methods: {
    ...mapActions("orders", [
      "fetchOrder",
      "sellerCancelsOnlineOrder",
      "cancelOnlineOrder",
      "deliveryConfirmation",
      "confirmedByDispatcher",
      "rejectedByDispatcher",
    ]),
    ...mapActions("printer", ["printEmidaInvoice"]),
    ...mapActions("pos", ["emptyCart"]),
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    dispatcherConfirms() {
      this.locationLink =
        "https://www.google.com/maps/search/?api=1&query=" +
        `${this.order.delivery_location.lat}` +
        "%2C" +
        `${this.order.delivery_location.lng}`;
      this.confirmedByDispatcher({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden aceptada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          });
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
        });
    },
    dispatcherRejects() {
      this.rejectedByDispatcher({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden rechazada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          });
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
        });
    },
    dispatcherConfirmsDelivery() {
      this.deliveryConfirmation({
        id: this.order.id,
      })
        .then((response) => {
          this.$swal({
            title: "Orden marcada como entrega!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          });
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
        });
    },
    cancelOrderByEstablishment() {
      this.sellerCancelsOnlineOrder({ id: this.order.id })
        .then((response) => {
          this.$swal({
            title: "Orden cancelada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          });
        });
    },
    async confirmCancelation() {
      try {
        const result = await Swal.fire({
          title:
            "¿Estás seguro que deseas cancelar esta orden? Esta acción no se puede revertir",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, cancelar",
          cancelButtonText: "NO",
          customClass: {
            confirmButton: "btnx btn-primary",
            cancelButton: "btnx btn-secondary ml-1",
          },
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          if (this.userData.customer) {
            this.cancelOrderByCustomer();
          } else {
            this.cancelOrderByEstablishment();
          }
          console.log('User clicked on the "OK" button.');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log(
            'User clicked on the "Cancel" button or closed the dialog.'
          );
        }
      } catch (error) {
        // Handle any errors that may occur during the Swal2 operation
        console.error("Error occurred:", error);
      }
    },
    cancelOrderByCustomer() {
      this.cancelOnlineOrder(this.order)
        .then((response) => {
          this.$swal({
            title: "Orden cancelada!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
          this.fetchOrder(this.$route.params.id).then((response) => {
            this.order = response;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages[0],
            },
          });
        });
    },
    printInvoice() {
      if (this.order.order_type === "emida") {
        if (JSON.parse(localStorage.getItem("print")) === true) {
          const parsedRequest = JSON.parse(this.order.emida_response.request);
          const orderToPrint = {
            order_type: {
              payment: this.order.order_payments_attributes[0].payment_type,
              type: "sell",
            },
            store: this.eliminarDiacriticos(this.order.store.name),
            products: {
              name: parsedRequest.productName,
              important_info: this.order.emida_response.response_message,
              autorization_code: this.order.emida_response.carrier_control_no,
              refence: this.order.emida_response.pin,
              unit_price: Number(this.order.total).toFixed(2),
            },
            total: Number(this.order.total),
            date: Date.now(this.order.created_at),
          };
          this.printEmidaInvoice({ ...orderToPrint });
        }
      } else {
        window.print();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  body.modal-open {
    visibility: hidden;
  }

  body.modal-open .modal .modal-body {
    visibility: visible; /* make visible modal body and header */
  }
  #logo {
    filter: invert(1);
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .pos-layout {
    display: none !important;
  }
  .hide-print,
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.btnx {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
}

/* Primary button style */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* Secondary button style */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: none;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
</style>
